<template>
  <v-app class="">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon scan"></v-icon>
          </div>
        </v-row> 
        <v-row>
        <!-- <input text
          label="Código"
          ref="textEan"
          :value="ean"
          @keydown.enter="searchByEanCode()"
          class="pr-4"
          v-if="tipoLista == 'simple'"
          v-show="!cargando"
          > -->
          <v-text-field
          label="Código"
          clearable
          ref="textEan"
          v-model.lazy="ean" 
          @keydown.enter="searchByEanCode()"
          class="pr-4"
          v-if="tipoLista == 'simple'"
          v-show="!cargando"
          ></v-text-field>
        </v-row>
       </div>
        <v-row>
          <v-switch
            class="mt-10"
            v-model="isOffline"
            @change="cambiarConexion()"
            v-if="tipoLista == 'simple'"
            label="Modo offline"
          ></v-switch>
        
          <v-btn v-show="!cargando" class="primary mt-13" text icon x-large absolute top right @click="abrirConsulta()">
            <v-icon color="white" v-if="tipoLista == 'simple'">mdi-help</v-icon>
            <v-icon color="white" v-else>mdi-close</v-icon>
          </v-btn>
        </v-row>
    </header>
    <Menu />
    
    <v-main class="pb-15 inventory2 margin-top pt-15"> 
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <div >
          <SimpleList @iniciar="iniciar" @updateIndexDb="updateIndexDb" @removeIndexDb="removeIndexDb" v-if="tipoLista === 'simple'"/>
          <AdvangeList @iniciar="iniciar" :total="total" v-else/>
        </div>
        <v-bottom-sheet
            v-model="sheet"
          >
            <v-sheet
              class="text-center py-5 px-3 inventory-sheet" 
            >
            <v-btn
                @click="sheet = !sheet"
                right
                fixed
                fab
                bottom
                class="secondary"
                elevation="0"
              > <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
              <v-radio-group
                class="mx-4 mt-3"
                v-model="tipoLista"
                column>
                <p class="subtitle-2 mb-3 text-uppercase grey--text">Modo de visualización de lista</p>
                <v-radio
                  label="Lista simple"
                  value="simple"
                ></v-radio>
                <v-radio
                  label="Lista avanzada"
                  value="Advange"
                ></v-radio>
              </v-radio-group>
            </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet
            v-model="sheet2"
          >
            <v-sheet
              class="text-center py-5 px-3 inventory-sheet" 
            >
            <v-btn
                @click="guardarInventario()"
                right
                fixed
                fab
                bottom
                class="secondary"
                elevation="0"
              > <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <h2 class="button text-uppercase text-left primary--text ml-2 font-weight-bold pb-5 mt-2">Selecciona opciones de inventario</h2>
            <v-radio-group
                v-model="tipo"
                column
                class="justify-center mx-4 mt-0"
                >
                <p class="subtitle-2 mb-3 text-uppercase grey--text">Modo de inventario</p>
                  <v-radio
                    label="Completo"
                    value="completo"
                  ></v-radio>
                  <v-radio
                    label="Parcial"
                    value="parcial"
                  ></v-radio>
            </v-radio-group>
            </v-sheet>
        </v-bottom-sheet>
        <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn fab text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn v-if="tipoLista === 'avanzado'" class="secondary" text icon x-large fixed bottom left @click="borrarInventario()">
          <v-icon color="white">mdi-cancel</v-icon>
        </v-btn> 
        <v-btn v-if="tipoLista === 'simple'" class="primary" text icon x-large fixed bottom left @click="scrollDown()">
          <v-icon color="white">mdi-arrow-down</v-icon>
        </v-btn> 
        <v-btn class="secondary" text icon x-large fixed bottom right @click="sheet2 = true">
          <v-icon color="white">mdi-check</v-icon>
        </v-btn> 
      </div>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import AdvangeList from '../components/AdvangeList.vue';
import SimpleList from '../components/SimpleList.vue';
import { TiendaApiService } from '../api/TiendaApiService';
import { InventarioApiService } from '../api/InventarioApiService';
const tiendaApiService = new TiendaApiService();
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'Ticket',
  components: {
    Menu,
    AdvangeList,
    SimpleList,
  },
  data() {
    return {
      e6: 0,
      snackbar: false,
      multiLine: true,
      snackColor: '',
      audioError: new Audio( require('@/assets/sonidos/error.mp3') ),
      audioOk: new Audio( require('@/assets/sonidos/ok.mp3') ),
      snackText: '',
      opId: '',
      opUid: '',
      ean: '',
      foto: '',
      tallas: [],
      dialog: false,
      tipo: 'completo',
      tipoLista: 'simple',
      sheet: false,
      sheet2: false,
      cargando: false,
      value: '',
      timeout: null,
      total: 0,
      db: null,
      lastId: 0,
      retryCount: 0,
      isOffline: this.$store.getters.getIsOffline
    };
  },
  computed: {
    cambiotienda () {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid () {
      return this.$store.getters.getOpuid;
    },
    articulosAvanzado () {
      return this.$store.state.inventarioArticulosAvanzado
    },
  },
  watch: {
    cambiotienda (value) {
      this.opId = value
      this.init()
    },
    cambiotiendaUid (value) {
      this.opUid = value
      this.getUltimasLineas()
      this.init()
    },
  },
  async mounted() {
    this.opId = this.$store.getters.getIdTienda
    this.opUid = this.$store.getters.getOpuid
    this.db = await this.instanciarIndexDb();
    this.init()
    
    // this.searchByEanCode('3700000500308')
  },
  methods: {
    init() {
      this.ean = '' 
      this.value = ''
      this.$refs.textEan.focus()
    },
    iniciar() {
      this.$nextTick(() => {
        this.init()
      }) 
    },
    cambiarConexion() {
        this.$root.$confirm.open('Conexión', 'Va a cambiar el modo de conexión ¿Desea continuar?', { color: 'primary' }).then((confirm) => {
          if (confirm) {
            this.$store.commit('setIsOffline', this.isOffline)
            this.getUltimasLineas()
            this.init()
          } else {
            this.isOffline = !this.isOffline
          }
        })
       
    },
    async instanciarIndexDb() {
      if (!('indexedDB' in window)) {
        this.snackbar = true
        this.snackColor = 'red darken-2'
        this.snackText = 'Este navegador no soporta el modo offline'
        return;
      }
      return new Promise((resolve, reject) => {

        let request = window.indexedDB.open("inventario", 4);
        
        request.onerror = e => {
          if (this.$store.state.inventarioArticulosSimple.length === 0 || this.isOffline) {
            this.getUltimasLineas()
          } 
          console.log('Error opening db', e);
          reject('Error');
        };
  
        request.onsuccess = e => {
          if (this.$store.state.inventarioArticulosSimple.length === 0 || this.isOffline) {
            this.getUltimasLineas()
          } 
          resolve(e.target.result);
        };
        
        request.onupgradeneeded = e => {
          console.log('onupgradeneeded');
          let db = e.target.result;
          db.createObjectStore("linea", { autoIncrement: true, keyPath:'msiId' });
        };
      });

    },
    getUltimasLineas() {
      this.$store.commit('setInventarioArticulosSimple', [])
      this.$store.commit('setInventarioArticulosAvanzado', [])
      this.$store.state.inventarioArticulosAvanzado = []
      this.total = 0
      if (!this.isOffline) {
        inventarioApiService.getUltimasLineasByOp(this.opUid, this.$store.state.userInfo.user.substring(0,50).toLowerCase())
          .then((data) => {
            if (data && data !== "") {
              data.forEach(element => {
                this.$store.commit('addToInventarioArticulosSimple', element)
              });
            }
          })
          .catch((error) => {
            this.snackbar = true
            this.snackColor = 'red darken-2'
            this.snackText = 'Ha ocurrido un error'
            console.log(error)
          })
      } else {
        this.leerIndexDb()
      }
    },
    retryDisp() {
      var vm = this
      if( ++vm.retryCount > 5 ) {
        console.log('Cannot open the database after 5 retries');
        vm.leerIndexDb();
      }
      setTimeout(function(){ vm.leerIndexDb() }, 100);
    },
    leerIndexDb() {
      //mostramos las ultimas 30 entradas leidas
      var vm = this 
        try {
          vm.cargando = true
          vm.inventarioOffline = []
          let cont = 0
          var objectStore = vm.db.transaction("linea").objectStore("linea");
          if(objectStore){
            objectStore.openCursor(null, 'prev').onsuccess = function(event) {
              var cursor = event.target.result;
              if (cursor) { 
                if (vm.opUid === cursor.value.msiOp && cursor.value.msiUsuario === vm.$store.state.userInfo.user.substring(0,50).toLowerCase()) {
                  vm.$store.commit('unshiftToInventarioArticulosSimple', { msiEan: cursor.value.msiEan, msiId: cursor.key, msiCantidad: cursor.value.msiCantidad })
                  vm.scrollDown()
                  if (cont === 0) {
                    vm.lastId = cursor.key
                  }
                  cont++
                }
                if (cont < 30) {
                  cursor.continue()
                }
              }
            }
            
            vm.cargando = false
          }
        }catch(e){
          vm.cargando = false
          console.log(e)
          vm.retryDisp()
        }
    },
    async addIndexDb(linea) {
      var vm = this
      ++vm.lastId
      return new Promise((resolve) => {
        var request = this.db.transaction(["linea"], "readwrite")
        .objectStore("linea").add({ 
          msiId: vm.lastId,
          msiEan: linea.msiEan, 
          msiOp: linea.msiOp,
          msiUsuario: linea.msiUsuario,
          msiCantidad: 1
        })
        request.onsuccess = e => {
          vm.audioOk.pause();         // Vuelve a pausar
          vm.audioOk.currentTime = 0; // Coloca la reproducción en el segundo 0 (inicio)
          vm.audioOk.play();
          vm.$store.commit('addToInventarioArticulosSimple', { msiEan: linea.msiEan, msiId: vm.lastId, msiCantidad: 1})
          vm.scrollDown()
          vm.init()
          resolve(e)
        }
        request.onerror = function(event) {
          vm.init()
          vm.audioError.pause();         // Vuelve a pausar
          vm.audioError.currentTime = 0; // Coloca la reproducción en el segundo 0 (inicio)
          vm.audioError.play();
          vm.snackbar = true
          vm.snackColor = 'red darken-2'
          vm.snackText = 'Ha ocurrido un error en la lectura'
          console.log(event)
        }
      })
    },
    removeIndexDb(id, index) { 
      var request =  this.db.transaction(["linea"], "readwrite")
      .objectStore("linea")
      .delete(id)
      request.onsuccess = () => { 
        this.snackbar = true
        this.snackColor = 'green'
        this.snackText = 'Articulo borrado correctamente'
        this.$store.commit('removeFromInventarioArticulosSimple',index)
      }
      request.onerror = function(event) {
        this.init()
        this.snackbar = true
        this.snackColor = 'red darken-2'
        this.snackText = 'Ha ocurrido un error'
        console.log(event)
      }
    },
    updateIndexDb(linea) {
      const objectStore = this.db.transaction(['linea'], 'readwrite')
                          .objectStore('linea')
      const request = objectStore.get(linea.msiId)
      request.onsuccess = ()=> {

          const lineaAux = request.result
          lineaAux.msiCantidad = parseInt(linea.msiCantidad);
          const updateRequest = objectStore.put(lineaAux)
          updateRequest.onsuccess = () => {
            this.snackbar = true
            this.snackColor = 'green'
            this.snackText = 'Articulo editado correctamente'
            this.$store.commit('editFromInventarioArticulosSimple', lineaAux)
          }
      }
      request.onerror = function(event) {
        this.init()
        this.snackbar = true
        this.snackColor = 'red darken-2'
        this.snackText = 'Ha ocurrido un error'
        console.log(event)
      }
    },
    getAllIndexDb() {
      var vm = this
      return new Promise((resolve, reject) => {
        vm.cargando = true
        const objectStore = vm.db.transaction(['linea'], 'readwrite')
                            .objectStore('linea')
        const request = objectStore.getAll()
        request.onsuccess = ()=> {
          vm.cargando = false
          resolve(request.result)
        }
        request.onerror = function(event) {
          vm.cargando = false
          vm.snackbar = true
          vm.snackColor = 'red darken-2'
          vm.snackText = 'Ha ocurrido un error al leer indexdb'
          console.log(event)
          reject(event)
        }
      })
    },
    clearIndexDb() {
      var vm = this
      return new Promise((resolve, reject) => {
        vm.cargando = true
        const objectStore = vm.db.transaction(['linea'], 'readwrite')
                            .objectStore('linea')
        const request = objectStore.clear()
        request.onsuccess = (e)=> {
          vm.cargando = false
          resolve(e)
        }
        request.onerror = function(event) {
          vm.cargando = false
          vm.snackbar = true
          vm.snackColor = 'red darken-2'
          vm.snackText = 'Ha ocurrido un error al borrar indexdb'
          console.log(event)
          reject(event)
        }
      })
    },
    searchByEanCodeScan(ean) {
      this.ean = ean
      this.searchByEanCode()
    },
    scrollDown() {
      this.$nextTick(() => {
        window.scrollTo(0,document.body.scrollHeight);
      }) 
    },
    searchByEanCode() {
      // this.ean = this.$refs.textEan.value
      this.ean = this.ean.trim()
      if (this.ean.length > 0) {
        if (this.tipoLista === 'simple') {
          if (this.$store.state.userInfo.user != '' && this.opUid !='' ) {
            let linea = {
              msiUsuario: this.$store.state.userInfo.user.substring(0,50).toLowerCase(),
              msiOp: this.opUid,
              msiEan: this.ean,
              msiCantidad: 1
            }
            if (!this.isOffline) {
              inventarioApiService.addLinea(linea)
                .then(data => {
                  this.audioOk.pause();         // Vuelve a pausar
                  this.audioOk.currentTime = 0; // Coloca la reproducción en el segundo 0 (inicio)
                  this.audioOk.play();
                  this.$store.commit('addToInventarioArticulosSimple', { msiEan: this.ean, msiId: data.msiId, msiCantidad: 1})
                  this.scrollDown()
                  this.init()
                })
                .catch(error =>{ 
                  this.init()
                  this.audioError.pause();         // Vuelve a pausar
                  this.audioError.currentTime = 0; // Coloca la reproducción en el segundo 0 (inicio)
                  this.audioError.play()
                  console.log(error)
                  this.snackbar = true
                  this.snackColor = 'red darken-2'
                  this.snackText = 'Ha ocurrido un error'
                })
            } else {
              this.addIndexDb(linea)
            }
          }
        } else {
          let encontrado
          for (var x=0; x <  this.articulosAvanzado.length; x++) {
            if (this.articulosAvanzado[x].ean === this.ean) {
              this.$store.commit('addUdInventarioArticulosAvanzado', this.ean)
              encontrado = true
              this.ean = '' 
              this.value = ''
              break
            }
          }
          if (!encontrado) {
            this.getProductBy(this.ean)
          }
        }
      } else {
        this.audioError.play()
        this.ean = ''
        this.value = ''
        this.snackbar = true
        this.snackColor = 'red darken-2'
        this.snackText = 'No se ha podido escanear el código'
      }
    },
    getProductBy(ean) {
      // var filtro = {}
      // Object.assign(filtro, { ean: ean })
      ean = ean.trim()
      tiendaApiService
        .getArticuloByCod(ean)
        .then((data) => {
          let foto, stock = 0, unidades = 1, talla, stockObj
          if (data.articulo !== null) {
            if (data.articulo.fotos.length > 0) {
              foto = data.articulo.fotos[0].url;
            } else {
              foto= ''
            }
            outer_loop:
            for (var x=0; x < data.articulo.tallas.length; x++) {
              for (var y=0; y < data.articulo.tallas[x].eans.length; y++) {
                if (data.articulo.tallas[x].eans[y].ean === ean) {
                  talla = data.articulo.tallas[x].talla
                  if (data.articulo.tallas[x].stocks.length > 0) {
                    stockObj = data.articulo.tallas[x].stocks.find(element => element.id.id_op === this.opId)
                    if (stockObj) {
                      stock = stockObj.unidades
                    }
                  }
                  break outer_loop
                }
              } 
            }
            this.$store.commit('addToInventarioArticulosAvanzado',{
                                'articulo': data.articulo,
                                'talla': talla,
                                'stock': stock,
                                'unidades': unidades,
                                'foto': foto,
                                'ean': ean,
                                })
            this.$nextTick(() => {
               window.scrollTo(0,document.body.scrollHeight);
            })   
            this.init()
          } else {
            this.snackbar = true
            this.snackColor = 'red darken-2'
            this.snackText = 'No se ha encontrado el articulo'
            this.init()
          }
        })
        .catch((error) => {
          this.init()
          this.snackbar = true
          this.snackColor = 'red darken-2'
          this.snackText = 'Ha ocurrido un error'
          console.log('tiendaApiService - findArticulos ' + error)
        });
    },
    guardarInventario() {          
      this.sheet2 = !this.sheet2 
      this.$root.$confirm.open('Enviar', '¿Deseas enviar el inventario?', { color: 'primary' }).then((confirm) => {
        if (confirm && (this.$store.state.inventarioArticulosSimple.length > 0 || this.$store.state.inventarioArticulosAvanzado.length > 0 )) {
          let inventario = []
          this.cargando = true
          inventario.push(this.tipo)
          inventario.push(this.opUid)
          inventario.push(this.$store.state.userInfo.user.substring(0,50).toLowerCase())
          if (this.isOffline) {
            this.getAllIndexDb().then((data) => {
              this.cargando = true
              inventario.push(data)
              inventarioApiService.enviarInventario(inventario)
                .then(() => {
                  this.clearIndexDb()
                  if (this.tipoLista === 'simple') {
                    this.$store.state.inventarioArticulosSimple = []
                  } else {
                    this.$store.state.inventarioArticulosAvanzado = []
                  }
                  this.cargando = false
                  this.snackbar = true
                  this.snackColor = 'green'
                  this.snackText = 'Inventario registrado con éxito'
                })
                .catch(error => {
                  this.cargando = false
                  this.snackbar = true
                  this.snackColor = 'red darken-2'
                  this.snackText = 'Error al enviar el inventarío'
                  console.log('tiendaApiService - enviarInventario ' + error)
                })   
            }) 
          } else {
            inventarioApiService.enviarInventario(inventario)
            .then(() => {
              if (this.tipoLista === 'simple') {
                this.$store.state.inventarioArticulosSimple = []
              } else {
                this.$store.state.inventarioArticulosAvanzado = []
              }
              this.cargando = false
              this.snackbar = true
              this.snackColor = 'green'
              this.snackText = 'Inventario registrado con éxito'
            })
            .catch(error => {
              this.cargando = false
              this.snackbar = true
              this.snackColor = 'red darken-2'
              this.snackText = 'Error al enviar el inventarío'
              console.log('tiendaApiService - enviarInventario ' + error)
            })
          }
        }
      })
    },
    borrarInventario() {
      this.$root.$confirm.open('Descartar', '¿Deseas descartar el inventario?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          this.cargando = true
          if (!this.isOffline) {
            inventarioApiService.borrarInventario(this.opUid, this.$store.state.userInfo.user.substring(0,50).toLowerCase())
            .then(() => {
              this.$store.state.inventarioArticulosSimple = []
              this.$store.state.inventarioArticulosAvanzado = []
              this.total = 0
              this.cargando = false
              this.snackbar = true
              this.snackColor = 'green'
              this.snackText = 'Inventario borrado con éxito'
            })
            .catch(error => {
              this.cargando = false
              this.snackbar = true
              this.snackColor = 'red darken-2'
              this.snackText = 'Error al descartar el inventarío'
              console.log('tiendaApiService - descartar ' + error)
            })
          } else {
            this.clearIndexDb().then(() => {
              this.$store.state.inventarioArticulosSimple = []
              this.$store.state.inventarioArticulosAvanzado = []
              this.total = 0
              this.cargando = false
              this.snackbar = true
              this.snackColor = 'green'
              this.snackText = 'Inventario borrado con éxito'
            })
          }
        }
      })
    },
    abrirConsulta() {
      this.total = 0
      this.$store.state.inventarioArticulosAvanzado = []
      if (this.tipoLista === 'simple') {
        this.cargando = true
        // si es SYS4 sacamos un resumen simple de eans con su unidades, si es twister sacamos mas informacion como foto modelo
        // Si estamos en modo offline tambien sacamos un resumen simple 
        if (!Vue.prototype.isSys4() && !this.isOffline) {
          tiendaApiService.getArticulosInventario(this.opUid)
            .then(data =>{
              if (data) {
                data.forEach(element => {
                  this.addArticuloAvanzado(element)
                  this.total += element.cantidad
                });
                this.tipoLista = "avanzado"
              } else {
                this.snackbar = true
                this.snackColor = 'red darken-2'
                this.snackText = 'No hay articulos'
              }
              this.cargando = false
            })
            .catch(error => {
              this.cargando = false
              this.snackbar = true
              this.snackColor = 'red darken-2'
              this.snackText = 'Ha ocurrido un error'
              console.log(error)
            })
        } else {
          if (!this.isOffline) {
            inventarioApiService.getResumenInventario(this.opUid, this.$store.state.userInfo.user.substring(0,50).toLowerCase())
              .then(data => {
                  data.forEach(element => {
                    this.$store.commit('addToInventarioArticulosAvanzado',{
                              'articulo': '',
                              'talla': '',
                              'stock': '',
                              'unidades': element[1],
                              'foto': '',
                              'ean': element[0],
                              })
                    this.total += element[1] 
                  });
                  this.cargando = false
                  this.tipoLista = "avanzado"
              })  
              .catch(error => {
                this.cargando = false
                this.snackbar = true
                this.snackColor = 'red darken-2'
                this.snackText = 'Ha ocurrido un error'
                console.log(error)
              })
          } else {
            this.getAllIndexDb()
              .then(data =>{
                 let resultAux = Object.values(data.reduce((a,b) => (
                           a[b.msiEan] = a[b.msiEan] ?
                           (a[b.msiEan].msiCantidad = parseInt(a[b.msiEan].msiCantidad) + parseInt(b.msiCantidad), a[b.msiEan]) :
                           b, a), {})
                  )
                  let result = resultAux.filter(item => {
                      if (item.msiOp === this.opUid && item.msiUsuario === this.$store.state.userInfo.user.substring(0,50).toLowerCase()) {
                        return item
                      }
                  })
                  result.forEach(element => {
                    this.$store.commit('addToInventarioArticulosAvanzado',{
                              'articulo': '',
                              'talla': '',
                              'stock': '',
                              'unidades': element.msiCantidad,
                              'foto': '',
                              'ean': element.msiEan,
                              })
                    this.total += parseInt(element.msiCantidad)
                  });
                  this.cargando = false
                  this.tipoLista = "avanzado"
              })
          }
        }
      } else {
        this.iniciar()
        this.tipoLista = "simple"
      }
    },
    addArticuloAvanzado(articulo) {
      let foto, stock = 0, talla, stockObj
      if (articulo.fotos.length > 0) {
          foto = articulo.fotos[0].url;
        } else {
          foto= ''
        }
        outer_loop:
        for (var x=0; x < articulo.tallas.length; x++) {
          for (var y=0; y < articulo.tallas[x].eans.length; y++) {
            if (articulo.tallas[x].eans[y].ean === articulo.ean) {
              talla = articulo.tallas[x].talla
              if (articulo.tallas[x].stocks.length > 0) {
                stockObj = articulo.tallas[x].stocks.find(element => element.id.id_op === this.opId)
                if (stockObj) {
                  stock = stockObj.unidades
                }
              }
              break outer_loop
            }
          } 
        }
        this.$store.commit('addToInventarioArticulosAvanzado',{
                            'articulo': articulo,
                            'talla': talla,
                            'stock': stock,
                            'unidades': articulo.cantidad,
                            'foto': foto,
                            'ean': articulo.ean,
                            })
        this.$nextTick(() => {
            window.scrollTo(0,document.body.scrollHeight);
        })   
    }
  },
});
</script>
<style>
.v-input--radio-group__input {
  justify-content: center;
}
.v-label {
  font-size: 20px;
}
.v-icon.v-icon {
  display: inline;
}
.fixed-row-bottom { position: fixed; bottom: 0;}
</style>