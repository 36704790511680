<template>
    <div>
      <v-list class="px-3 ma-0 col-12" >
        <v-list-item  class="d-flex my-3" v-for="(articulo, index) in articulosSimple" :key="articulo.msiId">
          <v-list-item-content class="text-subtitle-1 mb-0  primary--text pa-0 text-uppercase text-truncate text-overline">
            {{ articulo.msiEan }} / uds: {{ articulo.msiCantidad }} 
          </v-list-item-content>
            <v-btn text right top icon primary>
              <v-icon @click="editarCantidad(articulo.msiId, index, articulo.msiEan, articulo.msiCantidad)">mdi-pen</v-icon>
            </v-btn>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
      <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn fab text v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog persistent v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">Editar cantidad</v-toolbar-title>
          </v-toolbar>
          <v-card-title class="subheading">{{ ean }}
            <v-btn text right top icon primary class="pa-0">
              <v-icon @click="eliminar(id, index)">mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <div class="d-flex align-center px-6">
            <v-btn color="secondary" icon outlined>
              <v-icon @click="(cantidad>1) ? cantidad-- : ''">
                mdi-minus
              </v-icon>
            </v-btn>
            <v-text-field class="text-h5 px-4" v-model="cantidad" label="Unidades" type="number"></v-text-field>
            <v-btn color="secondary" icon outlined>
              <v-icon @click="cantidad++">
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog=false;$emit('iniciar')">Cancelar</v-btn>
            <v-btn color="secondary" @click="guardarCantidad()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import { InventarioApiService } from '../api/InventarioApiService';
const inventarioApiService = new InventarioApiService();
export default Vue.extend({
  name: 'SimpleList',
  components: {
  },
  computed: {
    articulosSimple () {
      return this.$store.state.inventarioArticulosSimple
    },
    isOffline() {
      return this.$store.state.isOffline
    },
  },
  data() {
    return {
      snackbar: false,
      multiLine: true,
      snackColor: '',
      snackText: '',
      dialog: false,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200
      },
      id:'',
      index:'',
      ean: '',
      cantidad: ''
    };
  },
  mounted() {

  },
  methods: {
    eliminar(id, index) {
      this.dialog = false
      this.$root.$confirm.open('Borrar', '¿Deseas borrar el articulo?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          if (!this.isOffline) {
            inventarioApiService.deleteLinea(id)
              .then((data) =>{
                if (data) {
                  this.$store.commit('removeFromInventarioArticulosSimple',index)
                  this.snackbar = true
                  this.snackColor = 'green'
                  this.snackText = 'Articulo borrado correctamente'
                  this.$emit('iniciar')
                } else {
                  this.snackbar = true
                  this.snackColor = 'red darken-2'
                  this.snackText = 'Ha ocurrido un error'
                }
              })
              .catch(error => {
                console.log(error)
                this.snackbar = true
                this.snackColor = 'red darken-2'
                this.snackText = 'Ha ocurrido un error'
              })
          } else {
            this.$emit('removeIndexDb',id, index)
          }
        }
      })
    },
    editarCantidad(id, index, ean, cantidad) {
      this.dialog = true
      this.id = id 
      this.index = index
      this.ean = ean
      this.cantidad = cantidad
    },
    guardarCantidad() {
      let linea = {
        msiId: this.id,
        msiCantidad: this.cantidad
      }
      if (!this.isOffline) {
        inventarioApiService.updateLinea(linea)
          .then((data) =>{
            if (data) {
              this.snackbar = true
              this.snackColor = 'green'
              this.snackText = 'Articulo editado correctamente'
              this.$store.commit('editFromInventarioArticulosSimple', data)
              this.$emit('iniciar')
            } else {
              this.snackbar = true
              this.snackColor = 'red darken-2'
              this.snackText = 'Ha ocurrido un error'
            }
            this.dialog = false
          })
          .catch(error => {
            console.log(error)
            this.dialog = false
            this.snackbar = true
            this.snackColor = 'red darken-2'
            this.snackText = 'Ha ocurrido un error'
          })
      } else {
        this.$emit('updateIndexDb', linea)
        this.dialog = false
      }  
    }
    
  }
});
</script>
